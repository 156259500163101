import { camelCase, snakeCase } from './string';

const isPrimitive = (val: unknown) => ['string', 'number', 'boolean'].includes(typeof val);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const localize = (obj: any, toStyle: (str: string, isUpper?: boolean) => string) => {
  // プリミティブ型なら変換しない
  if (isPrimitive(obj) || obj === null) {
    return obj;
  }

  // 配列の中身がオブジェクトの場合のみキーを変換する
  if (Array.isArray(obj)) {
    const dest = [];
    obj.forEach((a) => {
      dest.push(localize(a, toStyle));
    });
    return dest;
  }

  // オブジェクトのキーを変換する
  if (typeof obj === 'object') {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const dest: Record<string, any> = {};
    Object.keys(obj).forEach((a) => {
      dest[toStyle(a)] = localize(obj[a], toStyle);
    });
    return dest;
  }

  return obj;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const toCamelCaseKeys = (obj: any) => localize(obj, camelCase);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const toSnakeCaseKeys = (obj: any) => localize(obj, snakeCase);
