import axios from '@/js/lib/axios';
import { updateToken } from './update_token';
import { toCamelCaseKeys } from '@/js/lib/helper/localize-keys';

export interface NotificationItem {
  createdAt: string;
  id: number;
  picturePath: string | null;
  text: string;
  type: number;
  url: string;
}

export const markAsConfirmed = async ({ notificationId }: { notificationId: number }): Promise<''> => {
  const token = await updateToken();
  const params = new URLSearchParams();
  params.append('notification_id', String(notificationId));
  params.append('token', token);
  const res = await axios.post('/api/notification/confirm', params);
  return res.data;
};

export const getNotifications = async ({ sinceId, limit = 80 }: { sinceId?: number; limit?: number } = {}): Promise<{ notifications: NotificationItem[]; totalCount: number }> => {
  const res = await axios.get('/api/notification/list', {
    params: {
      since_id: sinceId,
      limit,
    },
  });
  return {
    notifications: toCamelCaseKeys(res.data),
    totalCount: Number(res.headers['x-total-count']),
  };
};

export const checkUpdated = async () => {
  const res = await axios.get('/api/notification/updated');
  return {
    updatedCount: Number(res.headers['x-updated-count']),
  };
};
