import common, { CdnDomain, type IichiEnv } from '@/js/lib/common';

export const cdnDomain = (env: IichiEnv): CdnDomain => CdnDomain[env];

export const cdnUrl = (path: string, env: IichiEnv = undefined): string => `${cdnDomain(env || common.env.Pallet.iichiEnv)}${path}`;

export const dynamicImageUrl = (imagePath: string, width?: number, height?: number): string => {
  let resize = '';

  if (width || height) {
    resize = '/r';

    if (width) {
      resize += `/w/${width}`;
    }

    if (height) {
      resize += `/h/${height}`;
    }
  }

  return cdnUrl(resize + imagePath);
};
