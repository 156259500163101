import axios from '@/js/lib/axios';
import { updateToken } from './update_token';
import { toCamelCaseKeys } from '@/js/lib/helper/localize-keys';

export const followMember = async ({ memberId }: { memberId: number }): Promise<''> => {
  const token = await updateToken();
  const params = new URLSearchParams();
  params.append('member_id', String(memberId));
  params.append('token', token);
  const res = await axios.post('/api/like/member', params);
  return res.data;
};

export const unFollowMember = async ({ memberId }: { memberId: number }): Promise<''> => {
  const token = await updateToken();
  const params = new URLSearchParams();
  params.append('member_id', String(memberId));
  params.append('token', token);
  const res = await axios.post('/api/like/unlink/member', params);
  return res.data;
};

export const isMemberFollowing = async ({
  memberIds,
}: {
  memberIds: number[];
}): Promise<
  {
    liked: 0 | 1;
    memberId: string;
  }[]
> => {
  const res = await axios.get('/api/is-liked/members', {
    params: {
      member_ids: memberIds.join(','),
    },
  });
  return toCamelCaseKeys(res.data);
};
