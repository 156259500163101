import { type Picture } from '@/types/photo-uploader';
import axios from '@/js/lib/axios';
import { updateToken } from './update_token';

export const messageSend = async ({ shopId, itemId, picture, subject, message }: { shopId: number; itemId?: number; picture?: Picture; subject?: string; message: string }): Promise<''> => {
  const token = await updateToken();
  const params = {
    item_id: itemId,
    to_member_id: shopId,
    picture: picture
      ? {
          filename: picture.filename,
        }
      : undefined,
    subject,
    message,
    token,
  };

  const res = await axios.post('/api/message/send', params);
  return res.data;
};

export const messageReply = async ({ conversationId, picture, message }: { conversationId: number; picture?: Picture; message: string }): Promise<''> => {
  const token = await updateToken();
  const params = {
    conversation_id: conversationId,
    picture: picture
      ? {
          filename: picture.filename,
        }
      : undefined,
    message,
    token,
  };

  const res = await axios.post('/api/message/reply', params);
  return res.data;
};

export const checkUnreadCount = async (): Promise<{ unreadCount: number }> => {
  const res = await axios.get('/api/message/unread-count');
  return {
    unreadCount: Number(res.headers['x-unread-count']),
  };
};
