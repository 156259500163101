import { ref } from 'vue';

export const useMQ = ({ width = 639 } = {}) => {
  const isPc = ref(false);
  const isSp = ref(true);
  const mediaQueryList = window.matchMedia(`(max-width: ${width}px)`);
  const listener = (event: MediaQueryList | MediaQueryListEvent) => {
    isPc.value = !event.matches;
    isSp.value = event.matches;
  };
  mediaQueryList.addEventListener('change', listener);
  listener(mediaQueryList);

  return { isPc, isSp };
};
