import 'scroll-behavior-polyfill';
import { isElement } from './is-element';

const scroll = ({ top }: { top: number }) => {
  window.scrollTo({
    top,
    behavior: 'smooth',
  });
};

const scrollToElement = ({ el }) => {
  if (!isElement(el)) {
    throw new TypeError(el);
  }

  scroll({ top: el.offsetTop });
};

const scrollToHash = ({ hash }: { hash: string }) => {
  const el = document.querySelector(hash);
  if (el) {
    scrollToElement({ el });
  }
};

export const scrollTo = ({ target = '' } = {}) => {
  if (isElement(target)) {
    scrollToElement({ el: target });
  } else if (target) {
    scrollToHash({ hash: target });
  } else {
    scroll({ top: 0 });
  }
};

export const applySmoothScroll = () => {
  const currentPath = `${window.location.protocol}//${window.location.host}${window.location.pathname}`;
  document.querySelectorAll('a[href*="#"]').forEach((a: HTMLAnchorElement) => {
    if (a.href.slice(0, currentPath.length) === currentPath) {
      a.addEventListener('click', (event) => {
        event.preventDefault();

        scrollTo({ target: a.hash });
      });
    }
  });
};
