import axios from '@/js/lib/axios';
import { updateToken } from './update_token';

export const credentials = {
  buyer: 'iichi.buyer',
  emailConfirmed: 'iichi.email_confirmed',
  bankAccountRegistered: 'iichi.bank_account_registered',
  foodSeller: 'iichi.food_seller',
  alcoholSeller: 'iichi.alcohol_seller',
  promoter: 'iichi.promoter',
  preSeller: 'iichi.pre.seller',
  seller: 'iichi.seller',
  trader: 'iichi.trader',
  wmsIntegrated: 'iichi.wms_integrated',
};

export const activityCount = async (): Promise<{ count: number }> => {
  const res = await axios.get('/api/user/activity-count');
  return res.data;
};

export const checkNewEvent = async (): Promise<''> => {
  const res = await axios.get('/api/user/check-new-event');
  return res.data;
};

export const checkNewItem = async (): Promise<''> => {
  const res = await axios.get('/api/user/check-new-item');
  return res.data;
};

export const signin = async ({ email, password, remember }): Promise<{ error: boolean }> => {
  const token = await updateToken();
  const params = new URLSearchParams();
  params.append('email', email);
  params.append('password', password);
  params.append('remember', remember);
  params.append('token', token);
  const res = await axios.post('/api/signin', params);
  return res.data;
};

export const signup = async ({ email, password, memberName, subscribeNewsletter, transactionToken }: { email: string; password: string; memberName: string; subscribeNewsletter: boolean; transactionToken: string }): Promise<''> => {
  const token = await updateToken();
  const params = new URLSearchParams();
  params.append('email', email);
  params.append('password', password);
  params.append('member_name', memberName);
  params.append('newsletter_subscription', subscribeNewsletter ? '1' : '0');
  params.append('token', token);
  params.append('transaction_token', transactionToken);
  const res = await axios.post('/api/signup', params);
  return res.data;
};
