import Vue from 'vue';
import { uniq } from '@/js/lib/helper/array';
import { pinia } from '@/js/lib/store';
import { useUserStore } from '@/js/lib/store/user';
import { useMemberStore } from '@/js/lib/store/member';
import FollowButton from '@/js/components/www.iichi.com/organisms/followButton.vue';
import FollowRecommend from '@/js/components/www.iichi.com/organisms/followRecommend.vue';
import FollowRecommendIfItemLiked from '@/js/components/www.iichi.com/organisms/followRecommendIfItemLiked.vue';

const userStore = useUserStore(pinia);
const memberStore = useMemberStore(pinia);

export const addFollowButton = async () => {
  const followButtonEls = document.querySelectorAll('follow-button');

  if (followButtonEls.length === 0) {
    return;
  }

  if (userStore.isAuthenticated) {
    const memberIds = Array.from(followButtonEls, ($member) => Number($member.getAttribute(':member-id')));
    await memberStore.getFollowedList(uniq(memberIds));
  }
  followButtonEls.forEach((el) => {
    new Vue({
      el,
      components: {
        FollowButton,
      },
    });
  });
};

export const addFollowRecommend = () => {
  new Vue({
    el: document.querySelector('follow-recommend'),
    components: {
      FollowRecommend,
    },
  });
};

export const addFollowRecommendIfItemLiked = () => {
  new Vue({
    el: document.querySelector('follow-recommend-if-item-liked'),
    components: {
      FollowRecommendIfItemLiked,
    },
  });
};
