import { mountModal } from './helper/dynamic-modal';
import SigninSignup from '@/js/components/common/ui/signin-signup.vue';

export const signinSignup = ({ active, next, callback }: { active?: string; next?: string; callback?: () => void } = {}) => {
  mountModal({
    Component: SigninSignup,
    props: {
      active,
      next,
      callback,
    },
  });
};
