import axios, { type AxiosStatic } from 'axios';

export default {
  ...axios,
  get: (url, config) => {
    const params = { _: Date.now(), ...config?.params };
    const headers = { 'X-Requested-With': 'XMLHttpRequest', ...config?.headers };
    try {
      return axios.get(url, config ? { ...config, params, headers } : { params, headers });
    } catch (error) {
      if (window.Sentry) {
        window.Sentry.captureException(error);
      }
      throw error;
    }
  },
} as AxiosStatic;
