const capitalize = (text: string) => `${text.charAt(0).toUpperCase()}${text.slice(1).toLowerCase()}`;

const isUpperCase = (char: string) => /^[A-Z]$/.test(char);

export const camelCase = (str: string, isUpper = false): string => {
  if (!str) {
    return str;
  }
  const strArr = str.split(/[ _-]+/).filter(Boolean);
  if (strArr.length === 1) {
    return str;
  }
  return strArr.map((str, index) => (index === 0 && !isUpper ? str.toLowerCase() : capitalize(str))).join('');
};

export const snakeCase = (str: string): string => {
  if (!str) {
    return str;
  }
  const strArr = [...str];
  if (strArr.length === 1) {
    return str;
  }
  return strArr.map((str) => (isUpperCase(str) ? `_${str.toLowerCase()}` : str)).join('');
};
