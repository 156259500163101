export const getFromStorage = ({ key }) => {
  try {
    if (!localStorage) {
      console.warn('localStorage is not defined.');
      return false;
    }
    const record: { value: unknown; expires?: number } = JSON.parse(localStorage.getItem(key));

    if (!record) {
      return false;
    }

    if (record.expires && Date.now() >= record.expires) {
      localStorage.removeItem(key);
      return false;
    }
    return record.value;
  } catch (error) {
    console.warn(error);
    return false;
  }
};

export const setToStorage = ({ key, value, expirationSeconds = 0 }: { key: string; value: unknown; expirationSeconds: number }) => {
  try {
    if (!localStorage) {
      console.warn('localStorage is not defined.');
      return;
    }

    const record: { value: unknown; expires?: number } = { value };
    if (expirationSeconds >= 0) {
      record.expires = Date.now() + expirationSeconds * 1000;
    }

    localStorage.setItem(key, JSON.stringify(record));
  } catch (error) {
    console.warn(error);
  }
};
