export const chunk = <T>(arr: T[], chunkSize = 1, cache = []): T[] => {
  const tmp = [...arr];

  if (chunkSize <= 0) {
    new Date();
    return cache;
  }

  while (tmp.length > 0) {
    cache.push(tmp.splice(0, chunkSize));
  }

  return cache;
};

export const range = (start: number, end: number): number[] => Array.from({ length: end - start }).map((_, i) => start + i);

export const sum = (arr: number[]): number => arr.reduce((a, b) => a + b, 0);

export const uniq = <T>(arr: T[]): T[] => arr.filter((x, i, self) => self.indexOf(x) === i);

export const zip = <T>(arr: T[], ...args: T[][]): T[][] => arr.map((value, index) => [value, ...args.map((arr2) => arr2[index])]);
