import axios from '@/js/lib/axios';
import { updateToken } from './update_token';

interface UploadResponse {
  filename: string;
  url: string;
}

export const uploadImage = async (file: File): Promise<UploadResponse> => {
  const token = await updateToken();
  const params = new FormData();
  params.append('image', file);
  params.append('token', token);
  const res = await axios.post('/api/upload/image', params);
  return res.data;
};
