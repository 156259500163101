const escape = (str: string) => str.replaceAll('&', '&amp;').replaceAll('"', '&quot;').replaceAll('<', '&lt;').replaceAll('>', '&gt;').replaceAll("'", '&#39;');

export const linkify = (str: string, iichiOnly = false) =>
  str.replaceAll(/(https?:\/\/[^\s&/:<>]+(?::\d+)?(?:\/[^\s"#&'()<>]*(?:#[^\s"&'()<>]+)?)?)|(.)/gi, (all, url, normal) => {
    if (url) {
      if (!iichiOnly || /^https?:\/\/((www|shop|my).)?iichi.com/.test(url)) {
        const safeUrl = escape(url);
        return `<a href="${safeUrl}">${safeUrl}</a>`;
      }
      return url;
    }
    return normal;
  });
