import axios from '@/js/lib/axios';
import { updateToken } from './update_token';
import { toCamelCaseKeys } from '@/js/lib/helper/localize-keys';

interface CartItem {
  itemId: number;
  quantity: number;
}

export interface CartBasket {
  shopId: number;
  items: CartItem[];
}

export const addToBasket = async ({ itemId, quantity = 1 }: { itemId: number; quantity?: number }): Promise<''> => {
  const token = await updateToken();
  const params = new URLSearchParams();
  params.append('item_id', String(itemId));
  params.append('quantity', String(quantity));
  params.append('token', token);
  const res = await axios.post('/api/cart/add', params);
  return res.data;
};

export const removeFromBasket = async ({ itemId }: { itemId: number }): Promise<{ error?: '1' }> => {
  const token = await updateToken();
  const params = new URLSearchParams();
  params.append('item_id', String(itemId));
  params.append('token', token);
  const res = await axios.post('/api/cart/remove_item', params);
  return res.data;
};

export const updateBasket = async ({ quantitiesLabel, quantitiesValue, shopIdLabel, shopIdValue }: { quantitiesLabel: string; quantitiesValue: string; shopIdLabel: string; shopIdValue: string }): Promise<''> => {
  const token = await updateToken();
  const params = new URLSearchParams();
  params.append(quantitiesLabel, quantitiesValue);
  params.append(shopIdLabel, shopIdValue);
  params.append('token', token);
  const res = await axios.post('/api/cart/update-basket', params);
  return res.data;
};

export const getBaskets = async (): Promise<CartBasket[]> => {
  const res = await axios.get('/api/cart/baskets');
  return toCamelCaseKeys(res.data);
};
