import axios from '@/js/lib/axios';
import { toCamelCaseKeys } from '@/js/lib/helper/localize-keys';

export interface TodoItem {
  picturePath: string | null;
  tag: string | null;
  text: string;
  timestamp: string;
  type: string;
  url: string;
}

export const getTodos = async (): Promise<{ todos: TodoItem[]; totalCount: number }> => {
  const res = await axios.get('/api/todo/list');

  return {
    todos: toCamelCaseKeys(res.data),
    totalCount: Number(res.headers['x-total-count']),
  };
};
